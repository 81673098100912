export const APP_ID_ROWER = 1;
export const APP_ID_ROWER_GROUP = 17;
export const APP_ID_BIKE = 128;
export const APP_ID_BIKE_GROUP = 384;
export const APP_ID_TM = 512;
export const APP_ID_TM_GROUP = 1536;

export const mapAppToGroup = {
  APP_ID_ROWER: APP_ID_ROWER_GROUP,
  APP_ID_BIKE: APP_ID_BIKE_GROUP,
  APP_ID_TM: APP_ID_TM_GROUP,
}

export const formatAppData = (app: any) => {
  if(app) {
    let res = [];
    for (let i = 0; i < app.length; i++) {
      const item = app[i];
      if(item.image) {
        res.push({
          ...item,
          id: item.id === APP_ID_ROWER ? APP_ID_ROWER_GROUP : item.id
        })
      }
    }
    return res;
  }
  return null
}